import React from 'react';
import isString from 'lodash/isString';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { API_URL, AppStatus, HELP_LINK, MAX_MESSAGES } from './const';

import './App.scss';

const AppStatusData: Record<AppStatus, { header: string, information: string, Action: JSX.Element, renderButton: boolean }> = {
  [AppStatus.INITIAL]: {
    header: 'Send to SFMC – Confirmation',
    information: 'Production package for this material will be sent to the PromoMats folder in SFMC Content Builder.',
    Action: (
      <p className="App__message">
        To cancel, click the Close button. Additional details can be found&#160;
        <a target="_blank" rel="noreferrer" href={HELP_LINK}>here</a>
        .
      </p>
    ),
    renderButton: true,
  },
  [AppStatus.SUCCESS]: {
    header: 'Send to SFMC - In Progress',
    information: 'Production package for this material is being sent to the PromoMats folder in SFMC Content Builder.',
    Action: (
      <>
        <p className="App__message">You will be notified via email when the process is complete.</p>
        <p className="App__message">This window can be closed now.</p>
      </>
    ),
    renderButton: false,
  },
  [AppStatus.FAILURE]: {
    header: 'Send to SFMC - Error',
    information: 'Something went wrong. Please try again after checking the internet connection.',
    Action: (
      <p className="App__message">
        You can find more information&#160;
        <a target="_blank" rel="noreferrer" href={HELP_LINK}>here</a>
        &#160;to help troubleshoot these errors.
      </p>
    ),
    renderButton: false,
  },
};

const App = (): JSX.Element => {
  const [userId, setUserId] = React.useState('');
  const [documentId, setDocumentId] = React.useState('');
  const [sessionId, setSessionId] = React.useState('');
  const [status, setStatus] = React.useState(AppStatus.INITIAL);
  const [errorDescription, setErrorDescription] = React.useState('');
  const [isSpinner, setIsSpinner] = React.useState(false);

  const {
    Action, information, header, renderButton,
  } = AppStatusData[status];

  const { search } = useLocation();
  React.useEffect(
    () => {
      const { userId, documentId, sessionId } = queryString.parse(search);
      setUserId(userId as string);
      setDocumentId(documentId as string);

      let messageCount = 0;

      const parseEvent = ({ data }: MessageEvent): void => {
        if (!isString(data)) {
          return;
        }

        try {
          const { message_id: messageId, data: messageData } = JSON.parse(data);

          if (messageId === 'session_id') {
            setSessionId(messageData.session_id);
            window.removeEventListener('message', parseEvent);
          }
        } catch (error) {
          console.log('Error occurred during parsing a message: ', error);
        } finally {
          if (messageCount++ > MAX_MESSAGES) {
            window.removeEventListener('message', parseEvent);
          }
        }
      };

      if (sessionId) {
        setSessionId(sessionId as string);
      } else {
        // https://developer.veevavault.com/docs/#sending-session-ids-with-post-message
        window.addEventListener('message', parseEvent);
        window.parent.postMessage(JSON.stringify({ message_id: 'ready', data: {} }), '*');
      }
    },
    [search, setDocumentId, setUserId, setSessionId],
  );

  const sendRequest = async () => {
    try {
      setIsSpinner(true);

      const response = await fetch(`${API_URL}/api`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: sessionId,
        },
        body: JSON.stringify({ userId, documentId, sessionId }),
      });

      if (!response.ok) {
        const { error } = await response.json();

        // error format from BE
        setErrorDescription(error?.description);
      }

      setStatus(response.ok ? AppStatus.SUCCESS : AppStatus.FAILURE);
    } catch (error) {
      setStatus(AppStatus.FAILURE);
    } finally {
      setIsSpinner(false);
    }
  };

  return (
    <div className="App">
      <h2 className="App__header">{header}</h2>
      <p className="App__message">{errorDescription || information}</p>
      {Action}
      {
        renderButton && !isSpinner
          ? (
            <button
              type="button"
              disabled={!userId || !documentId || !sessionId}
              className="App__button"
              onClick={sendRequest}
            >
              Ok
            </button>
          )
          : null
      }
      {
        isSpinner
          ? <span className="Spinner" />
          : null
      }
    </div>
  );
};

export default App;
